@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.screen-layout {
  height: calc(100vh - 200px)
}

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 15px;
  font-size: 1.5rem;
}

@layer components{

  *{
    @apply text-keylime-text text-[15px]
  }

  .btn {
    @apply flex rounded-[4px] cursor-pointer justify-center items-center font-medium uppercase text-sm;
  }

  .btn.btn-s {
    @apply px-6 py-1;
  }
  .btn.btn-m {
    @apply w-fit px-3 py-[6px];
  }

  .btn.btn-primary {
    @apply border bg-keylime-green-1 border-keylime-green-2 hover:bg-keylime-green-2 hover:border-keylime-green-4 font-semibold text-white ;
  }
  .btn.btn-primary-disabled {
    @apply border bg-keylime-green-5 border-keylime-green-2 hover:cursor-not-allowed text-white ;
  }

  .h1 {
    @apply text-keylime-green-1 text-[36px] leading-[1.1] mt-14 pb-[9.5px] font-normal box-border
  }

  .h3{
    @apply text-2xl text-keylime-gray-2 mt-5 mb-[10px] font-medium
  }

  .link {
    @apply text-blue-900 text-sm font-semibold hover:underline cursor-pointer
  }

  .simple-text{
    @apply text-[15px] mb-[10px]
  }

  summary{
    @apply text-keylime-gray-2 italic my-3 cursor-pointer
  }

  .nav-button {
    @apply py-[8.5px] px-[15px] text-white md:hover:bg-white  md:hover:text-keylime-green-1  md:text-keylime-gray-3 font-normal hover:bg-keylime-green-3
  }
}
